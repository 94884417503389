import { tns } from "tiny-slider"

function initDestinationCarousel(id) {
    var sliderDestination = tns({
        container: '.destination-carousel-container-' + id,
        mode: 'carousel',
        items: 1,
        gutter: 24,
        loop: true,
        autoplay: false,
        controlsContainer: '.destinationControls-' + id,
        prevButton: '.destination-previous-' + id,
        nextButton: '.destination-next-' + id,
        navPosition: 'bottom',
        lazyload: false,
        responsive: {
            900: {
                gutter: 42,
                items: 2,
            }
        }
    });
}

window.initDestinationCarousel = initDestinationCarousel;