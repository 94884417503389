import { tns } from "tiny-slider"

function initImageCarousel(id) {
    var sliderCarousel = tns({
        container: '.carousel-container-' + id,
        mode: 'carousel',
        autoWidth: true,
        items: 1,
        gutter: 20,
        loop: true,
        autoplay: false,
        controlsContainer: '.image-controls-' + id,
        prevButton: '.image-previous-' + id,
        nextButton: '.image-next-' + id,
        navPosition: 'bottom',
        lazyload: false,
    });
}

window.initImageCarousel = initImageCarousel;